import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 100%;
  padding: 0;
  z-index: 1;

  ${mq('l', true)} {
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 57%;
  background: #5A41F3 none;

  ${mq('s')} {
    width: 100%;
  }

  .inner {
    max-width: 710px;
    padding: 20% 40px 20% 20px;

    ${mq('s')} {
      padding: 130px 20px 60px;
      text-align: center;
    }
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 60px;

    ${mq('s')} {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
`;

export const Visual = styled.div`
  position: relative;
  width: 43%;

  ${mq('s')} {
    display: none;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 93%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 0;
  }

`;