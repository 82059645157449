import styled from 'styled-components';
import variables, { mq, fs, screenSize } from '../../utils/variables';

export const FooterContainer = styled.footer`
  background-color: #1E1433;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1230px;
  padding: 50px 35px 50px;

  ${mq('s')} {
    flex-direction: column;
    align-items: flex-start;
    padding: 35px 20px;
  }

  img {
    margin: 0;

    ${mq('s')} {
      margin-bottom: 20px;
    }
  }

  ul {
    margin: 0;
    display: flex;
    list-style-type: none;

    ${mq('s')} {
      flex-direction: column-reverse;
    }


    li {
      color: #fff;
      font-weight: 400;

      + li {
        margin-left: 25px;

        ${mq('s')} {
          margin-left: 0;
        }

        &::before {
          content: '•';
          margin-right: 25px;
          color: #fff;

          ${mq('s')} {
            display: none;
          }
        }
      }

      a {
        font-weight: 600;
        text-decoration: none;
        color: #fff;
        transition: color .3s ease-in-out;

        &:hover {
          color: #5A41F3;
        }
      }
    }
  }
`;
