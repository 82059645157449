import PropTypes from "prop-types"
import React, { useState, createRef, useEffect } from "react";
import logo from '../../images/logo-white.svg';

import {
  FooterContainer,
  Container
} from "./styled"

const Footer = () => {

  useEffect(() => {
  }, [])

  return (
    <FooterContainer>
      <Container>
        <a href="/">
          <img src={logo} alt="Manage" />
        </a>

        <ul>
          <li>
            © 2021 Manage. Tous droits réservés
          </li>
          {/* <li>
            <a href="#">Mention légales</a>
          </li> */}
        </ul>
      </Container>
    </FooterContainer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
