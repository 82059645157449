import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '../common/button';

import cardPme from '../../images/pme.svg';
import cardConsult from '../../images/consult.svg';
import cardSecretariat from '../../images/secretariat.svg';

import { Container, Cols, Col, Card } from './styled.js';

const Who = (props) => {
  return (
    <Container>
      <Cols>
        <Col id={(props.id) ? props.id : ''}>
          <h2 className="title">
            <small>qui utilise manage ?</small>
            Manage est la plateforme qui unit <strong>tous les acteurs des ressources humaines.</strong>
          </h2>
        </Col>

        <Col>
          <div className="intro">
            
              <p>Pour être concurrentielle, une entreprise doit sélectionner les bonnes personnes en adéquation avec sa vision, son budget, son équipe et ses besoins de compétences.</p>
              <p>En sachant que l'humain évolue en permanence et que le business d'une entreprise aussi, ces besoins ont un impact sur les RH quotidiennement. Manage vous aide à toujours aller de l’avant.</p>
            
          </div>

          <Card>
            <div className="content">
              <h3>Petites et moyennes entreprises</h3>
              <p>Manage se donne pour mission d’aider les PME en pleine croissance à répondre aux “nouveaux” enjeux du hard et soft RH.</p>
            </div>
            <div className="visual">
              <img src={cardPme} alt="" />
            </div>
          </Card>

          <Card>
            <div className="content">
              <h3>Entreprises de consultance</h3>
              <p>Manage soutient les entreprises de consultance en mettant en place une gestion spécifique à leurs besoins. Que ce soit une gestion qui dépasse les frontières et/ou un management de plusieurs centaines de consultants, Manage est là pour vous.</p>
            </div>
            <div className="visual">
              <img src={cardConsult} alt="" />
            </div>
          </Card>
        </Col>
        <div class="row">
          <Col>
            <Card className="card">
              <div className="content">
                <h3>Secrétariats sociaux</h3>
                <p>Manage travaille main dans la main avec certains secrétariats sociaux afin d’assurer la meilleure interface possible pour vos employés et automatiser au maximum vos tâches mensuelles.</p>
              </div>
              <div className="visual">
                <img src={cardSecretariat} alt="" />
              </div>
            </Card>
          </Col>

          <Col>
            <h3 className="highlight">Que votre entreprise soit installée dans un ou plusieurs pays, <strong>Manage s’adapte à vos besoins.</strong></h3>
          </Col>
        </div>
      </Cols>
    </Container>
  );
};

Who.propTypes = {
}

Who.defaultProps = {
}

export default Who
