import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '../common/button';

import { Container, Header, Row, Cards, Card, ShowAllWrapper } from './styled.js';

import competences from '../../images/features/02-owl-laptop-study 1.svg';
import planning from '../../images/features/03-calendar 1.svg';
import actifs from '../../images/features/03-pen-paper-writing 1.svg';
import voitures from '../../images/features/04-car-3 1.svg';
import organigramme from '../../images/features/06-nuclear-power-energy 1.svg';
import collaborateurs from '../../images/features/07-hand-shaking 1.svg';
import performances from '../../images/features/10-success-badge 1.svg';
import inOuts from '../../images/features/16-employee-tag 1.svg';
import absences from '../../images/features/16-travel-case 1.svg';
import depenses from '../../images/features/18-receipt 1.svg';
import payroll from '../../images/features/19-money-bag 1.svg';
import planFinancier from '../../images/features/104-inspecting-bill-finance-document 1.svg';

const Features = () => {

  const [showAll, setShowAll] = useState(false);

  return (
    <Container>
      
        <div>
          <Row>
            <Header>
              <h2>Ce que Manage <strong>vous propose.</strong></h2>
            </Header>
            <div className='cards'>
              <Card className="top">
                <div className="visual">
                  <img src={collaborateurs} alt='' />
                </div>
                <div className="content">
                  <h3>Collaborateurs</h3>
                  <p>Signalétique des collaborateurs internes &amp; externes</p>
                </div>
              </Card>

              <Card className="top">
                <div className="visual">
                  <img src={payroll} alt='' />
                </div>
                <div className="content">
                  <h3>Payroll</h3>
                  <p>Calculs et paiements des salaires et des primes</p>
                </div>
              </Card>

              <Card className="top">
                <div className="visual">
                  <img src={inOuts} alt='' />
                </div>
                <div className="content">
                  <h3>In &amp; Outs</h3>
                  <p>Arrivées, onboarding et départs des collaborateurs</p>
                </div>
              </Card>

              <Card className="top">
              <div className="visual">
                <img src={depenses} alt='' />
              </div>
              <div className="content">
                <h3>Dépenses</h3>
                <p>Administration des dépenses professionnelles</p>
              </div>
            </Card>
            </div>
          </Row>
        </div>
        
        <Cards className={(showAll) ? 'opened' : 'closed'}>

          <Card>
            <div className="visual">
              <img src={absences} alt='' />
            </div>
            <div className="content">
              <h3>Absences</h3>
              <p>Gestion des absences volontaires ou involontaires</p>
            </div>
          </Card>

          <Card>
            <div className="visual">
              <img src={voitures} alt='' />
            </div>
            <div className="content">
              <h3>Voitures</h3>
              <p>Gestion du cycle de vie des voitures de société</p>
            </div>
          </Card>

          <Card>
            <div className="visual">
              <img src={actifs} alt='' />
            </div>
            <div className="content">
              <h3>Actifs</h3>
              <p>Administration de l’affectation des actifs de l’entreprise</p>
            </div>
          </Card>

          <Card>
            <div className="visual">
              <img src={competences} alt='' />
            </div>
            <div className="content">
              <h3>Compétences</h3>
              <p>Gestion et évolution des compétences des collaborateurs.<strong className="soon">(bientôt)</strong></p>
            </div>
          </Card>

          <Card>
            <div className="visual">
              <img src={organigramme} alt='' />
            </div>
            <div className="content">
              <h3>Organigramme</h3>
              <p>Administration de la hiérarchie et des départements<strong className="soon">(bientôt)</strong></p>
            </div>
          </Card>

          <Card>
            <div className="visual">
              <img src={planning} alt='' />
            </div>
            <div className="content">
              <h3>Planning</h3>
              <p>Administration  des plannings de travail des collaborateurs <strong className="soon">(bientôt)</strong></p>
            </div>
          </Card>

          <Card>
            <div className="visual">
              <img src={performances} alt='' />
            </div>
            <div className="content">
              <h3>Performances</h3>
              <p>Evaluation des performances des collaborateurs <strong className="soon">(bientôt)</strong></p>
            </div>
          </Card>

          <Card>
            <div className="visual">
              <img src={planFinancier} alt='' />
            </div>
            <div className="content">
              <h3>Plan financier</h3>
              <p>Aide à la mise en place de plan financier <strong className="soon">(bientôt)</strong></p>
            </div>
          </Card>
        </Cards>

        <ShowAllWrapper className={(showAll) ? 'opened' : 'closed'}>
          <button
            onClick={() => {
              setShowAll(!showAll);
            }}
          >
            {(showAll) ? (<span>Voir moins</span>) : (<span>Voir plus</span>)}
          </button>
        </ShowAllWrapper>
      
    </Container>
  );
};

Features.propTypes = {
}

Features.defaultProps = {
}

export default Features
