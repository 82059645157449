import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

export const Container = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-ends;
  margin: 0 auto;
  max-width: 1220px;
  padding: 80px 20px 0;

  ${mq('s')} {
    padding-top: 20px;
  }
`;

export const Header = styled.div`
  width: calc(50% - 20px);
  margin: 0 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;

  ${mq('l')} {
    width: calc(100% - 20px);
    margin-bottom: 50px;
  }

  ${mq('s')} {
    margin: 0 0 30px;
    padding: 0;
    width: 100%;
  }

  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.01em;
    text-align: left;
    max-width: 480px;
    margin: 0 auto;

    ${mq('l')} {
      max-width: none;
    }

    ${mq('s')} {
      font-size: 28px;
      line-height: 30px;
    }

    strong {
      color: #5A41F3;
      font-weight: 600;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  .cards {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  ${mq('s')} {
    position: relative;
    overflow: hidden;
    max-height: 2000px;
    transition: max-height 1s ease-in-out;

    width: calc(100% + 40px);
    padding: 0 20px;
    margin: 0 -20px;
    flex: none;
  }

  &::after {
    ${mq('s')} {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 0;
      background: linear-gradient(0, rgba(255,255,255,1) 50%, rgba(255,255,255,0.0001) 100%);
      opacity: 0;
      transition: opacity 1s ease-in-out, height 1s ease-in-out;
    }
  }

  &.closed {
    ${mq('s')} {
      max-height: 140px;
    }

    &::after {
      ${mq('s')} {
        height: 90px;
        opacity: 1;
      }
    }
  }
`;

export const ShowAllWrapper = styled.div`
  display: none;

  ${mq('s')} {
    display: block;
    width: calc(100% + 40px);
  }

  &.opened {
  }

  button {
    width: 100%;
    text-align: center;
    background: transparent;
    border: 0;
    padding: 10px 20px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #5A41F3;
  }
`;

export const Card = styled.div`
  display: flex;
  width: calc(25% - 20px);
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 6px 16px rgba(30, 20, 51, 0.1);
  border-radius: 8px;
  margin: 0 10px 20px;

  ${mq('l')} {
    width: calc(50% - 20px);
  }

  ${mq('s')} {
    width: 100%;
    margin: 0 0 20px;
  }

  &.top {
    width: calc(50% - 20px);

    ${mq('s')} {
      width: 100%;
    }
  }

  .visual {
    margin-right: 10px;
  }

  .content {
    flex: 1;

    h3 {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.01em;
      color: #1E1433;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #787285;
      margin-bottom: 0;

      .soon {
        display: block;
        color: #08847C;
        font-weight: 600;
      }
    }
  }
`;