import styled from 'styled-components';
import variables, { mq, screenSize, fs } from '../../utils/variables';

export const Section = styled.div`
  position: relative;
  background-color: ${ props => props.dark ? variables.colors.backgroundDark : ((props.light) ? variables.colors.backgroundExtraLight : 'transparent')};

  > * {
    position: relative;
    z-index: 2;
  }

  &.cutted {
    overflow: hidden;
  }

  &.white {
    background: #fff;
  }

  &.deco-1 {
    &::before {
      content: '';
      display: block;
      width: 70%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-70%, 0) rotate(133deg);
      padding-bottom: 100%;
      background: rgb(238,235,251) none;

      ${mq('s')} {
        display: none;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 70%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(18.8%,104.2%) rotate(133deg);
      padding-bottom: 70%;
      background: rgb(252,251,252) none;

      ${mq('s')} {
        display: none;
      }
    }
  }

  &.deco-2 {
    &::before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-15%, 20%) rotate(47deg);
      padding-bottom: 67%;
      background: rgb(238,235,251) none;
      border-radius: 1.67%;

      ${mq('s')} {
        display: none;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 70%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(58%,-64.3%) rotate(47deg);
      padding-bottom: 70%;
      background: rgb(252,251,252) none;

      ${mq('s')} {
        display: none;
      }
    }
  }

  &.deco-3 {
    &::before {
      content: '';
      display: block;
      z-index: 1;
      width: 98%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-35%, 30%) rotate(47deg);
      padding-bottom: 67%;
      background: rgb(238,235,251) none;
      border-radius: 1.67%;

      ${mq('s')} {
        display: none;
      }
    }
  }

  .visual-screenshot-wrapper {
    margin: 0 auto 10%;
    max-width: 1040px;
  }
  .visual-screenshot {
    position: relative;
    background: #fff;
    border-radius: 20px;
    padding: 20px;
    
    margin: 0 20px;

    box-shadow: 10px 45px 50px 0px rgba(0,0,0,0.15);

    ${mq('s')} {
      padding: 0;
      margin-bottom: 20px;
      box-shadow: none;
    }

    &::before {
      /* content: '';
      position: absolute;
      z-index: 0;
      background: #CFCFDB;
      filter: blur(124px);
      width: calc(100% - 130px);
      height: calc(100% - 250px);
      bottom: 0px;
      left: 150px;
      right: 100px; */
    }


    img {
      position: relative;
      z-index: 2;
      display: block;
      border-radius: 15px;
      overflow: hidden;
      margin:0;
    }
  }

`;