import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '../common/button';

import { Container, Content } from './styled.js';

const FooterBanner = () => {
  return (
    <Container>
      <Content>
        <h2>Manage vous intéresse ?</h2>
        <Button href="mailto: juliette@hellomanage.app" target="_blank" inverted={true}><span>Contactez nous</span></Button>
      </Content>
    </Container>
  );
};

FooterBanner.propTypes = {
}

FooterBanner.defaultProps = {
}

export default FooterBanner
