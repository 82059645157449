const variables = {
  breakpoints: {
    's': 690,
    'm': 960,
    'l': 1024,
    'xl': 1180,
    'xxl': 1440,
    'xxxl': 1920,
  },
  colors: {
    white: '#fff',
    black: '#000',
  }
};

export const mq = (breakpoint, min) => `@media screen and (${(min === true) ? 'min' : 'max'}-width: ${variables.breakpoints[breakpoint]}px)`;

export const fs = (size) => {
  const sizePx = size / 1.14;
  const sizeVw = sizePx / 17;
  const sizePxLarge = size;

  return `
    ${mq('xxl')} {
      font-size: ${sizePx}px;
    }

    ${mq('xxl', true)} {
      font-size: ${sizeVw}vw;
    }

    ${mq('xxxl', true)} {
      font-size: ${sizePxLarge}px;
    }
  `;
}

export const screenSize = (property, size) => {
  const sizePx = size / 1.14;
  const sizeVw = sizePx / 17;
  const sizePxLarge = size;

  return `
    ${mq('xxl')} {
      ${property}: ${sizePx}px;
    }

    ${mq('xxl', true)} {
      ${property}: ${sizeVw}vw;
    }

    ${mq('xxxl', true)} {
      ${property}: ${sizePxLarge}px;
    }
  `;
}

export const isTouch = () => {  
  try {  
      document.createEvent("TouchEvent");  
      return true;  
  } catch (e) {  
      return false;
  }  
}

export const isTouchMedia = (hover = 'hover') => `@media (hover: ${hover})`;

export default variables;