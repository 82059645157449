import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

import cardYellow from '../../images/how-card-yellow.svg';
import cardOrange from '../../images/how-card-orange.svg';
import cardGreen from '../../images/how-card-green.svg';
import cardRed from '../../images/how-card-red.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background: #5A41F3;
  position: relative;
  z-index: 1;
`;

export const Content = styled.div`
  max-width: 1220px;
  padding: 160px 20px 80px;
  text-align: center;

  ${mq('s')} {
    padding-top: 135px;
    padding-bottom: 35px;
  }
  

  h2 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 55px;

    ${mq('s')} {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
`;

