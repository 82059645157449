import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 1180px;
  padding: 80px 20px 0;

  ${mq('s')} {
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  padding: 150px 0 240px;
  width: 45%;

  ${mq('l')} {
    padding: 70px 0 220px;
    width: 55%;
  }

  ${mq('m')} {
    padding: 70px 0 130px;
    width: 55%;
  }

  ${mq('s')} {
    padding: 85% 0 75px;
    width: 100%;
  }

  .soon {
    display: inline-block;
    background: #83C2BE;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    border-radius: 8px;
    padding: 5px 10px;
    margin: 0 0 25px;
  }

  .title {
    font-size: 64px;
    font-weight:600;
    color: #1E1433;
    line-height: 62px;
    margin-bottom: 30px;

    ${mq('s')} {
      font-size: 36px;
      line-height: 40px;
    }

    strong {
      display: block;
      color: #5A41F3;
      font-weight: 600;
    }
  }

  p {
    font-size: 20px;
    line-height: 26px;
    color: #4B435C;
    margin-bottom: 40px;

    ${mq('s')} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const Visual = styled.div`
  z-index: 0;
  height: 140%;
  width: 145%;
  position: absolute;
  display: block;
  left: 40%;
  top: -20%;
  right: -20%;
  bottom: -10%;

  ${mq('l')} {
    top: -20%;
  }
  
  ${mq('m')} {
    top: 2%;
  }

  ${mq('s')} {
    width: 200%;
    left: 5%;
    bottom: auto;
    top: -20%;
  }
  
  img {
    display: block;
  }
`;