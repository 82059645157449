import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

import cardYellow from '../../images/how-card-yellow.svg';
import cardOrange from '../../images/how-card-orange.svg';
import cardGreen from '../../images/how-card-green.svg';
import cardRed from '../../images/how-card-red.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1220px;
  padding: 160px 20px 170px;

  ${mq('m')} {
    padding-top: 70px;
    padding-bottom: 80px;
  }

  ${mq('s')} {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100vw;
    width: 1000vw;
    height: 50px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.001) 100%);
    z-index: 5;
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 5;
  width: 40%;
  padding: 40px 0 0;

  ${mq('l')} {
    width: 60%;
  }

  ${mq('s')} {
    width: 100%;
  }

  .title {
    
    font-size: 40px;
    font-weight: 600;
    color: #1E1433;
    max-width: 463px;

    ${mq('s')} {
      font-size: 28px;
    }

    small {
      display: block;
      font-size: 12px;
      font-weight: 600;
      color: #ACA0F9;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      margin-bottom: 10px;
    }

    strong {
      font-weight: 600;
      color: #5A41F3;
    }
  }
`;

export const Visual = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(49% + 20px);
  margin: 0;
  padding-bottom: calc(393px * 0.20);

  ${mq('l')} {
    width: calc(29% + 20px);
  }

  ${mq('s')} {
    width: 100%;
    padding-bottom: 0;
  }

  img {
    position: absolute;
    bottom: 0;
    left: calc(49% + 20px);
    height: 150%;
    width: auto;
    max-width: 130%;
    z-index: 4;

    ${mq('s')} {
      display: none;
    }

    &.mobile-small {
      display: none;

      ${mq('s')} {
        display: block;
        position: relative;
        bottom: auto;
        left: auto;
        height: auto;
        width: 100%;
      }
    }
  }
`;
