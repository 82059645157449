import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import logo from '../../images/logo.svg';
import { Button } from '../common/button';

import { Container, Visual, Content } from './styled.js';

import heroVisual from '../../images/hero-visual.png';

const Hero = () => {
  return (
    <Container>
      <Content>
        <span className="soon">Bientôt disponible</span>
        <h1 className="title">
          <strong>Manage</strong>
          your career path.
        </h1>
        <p>La plateforme online sécurisée qui offre aux entreprises une gestion globale et optimale des ressources humaines.</p>
        <Button href="mailto: juliette@hellomanage.app" target="_blank"><span>Contactez-nous</span></Button>
      </Content>

      <Visual>
        <img src={heroVisual} />
      </Visual>
    </Container>
  );
};

Hero.propTypes = {
}

Hero.defaultProps = {
}

export default Hero
