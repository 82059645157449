import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '../common/button';

import { Container, Content, Visual } from './styled.js';

import Map from '../../images/where-map.svg';
import MapMobile from '../../images/where-map-mobile.svg';

const Where = (props) => {
  return (
    <Container>

      <Content id={(props.id) ? props.id : ''}>
        <h2 className="title">
          <small>dans quel pays ?</small>
          Il est possible d’utiliser Manage si vous êtes en <strong>France, en Belgique ou au Luxembourg.</strong> Pour les autres pays, encore un peu de patience, on arrive.
        </h2>
      </Content>

      <Visual>
        <img src={Map} />
        <img src={MapMobile} className="mobile-small" />
      </Visual>
    </Container>
  );
};

Where.propTypes = {
}

Where.defaultProps = {
}

export default Where
