import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '../common/button';

import { Container, Content, Visual } from './styled.js';

import manWithPhone from '../../images/man-with-phone.png';

const Demo = () => {
  return (
    <Container>
      <Content>
        <div className="inner">
          <h2 className="title">Vous désirez voir comment fonctionne Manage ?</h2>
          <Button href="mailto: juliette@hellomanage.app" target="_blank" inverted={true}><span>Contactez nous</span></Button>
        </div>
      </Content>
      <Visual>
        <img src={manWithPhone} />
      </Visual>
    </Container>
  );
};

Demo.propTypes = {
}

Demo.defaultProps = {
}

export default Demo
