import styled from 'styled-components';
import variables, { mq, screenSize, fs } from '../../utils/variables';

export const Button = styled.a`
  display: inline-flex;
  align-items: center;

  padding: 0 38px;
  height: 44px;
  border-radius: 48px;
  background:  ${(props) => props.inverted ? '#fff' : '#5A41F3'};
  color: ${(props) => props.inverted ? '#5A41F3' : '#fff'};
  font-family: 'Sofia Pro', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid ${(props) => props.inverted ? '#fff' : '#5A41F3'};
  text-decoration: none;

  span {
    margin-top: -4px;
  }

  &:hover {
    background: ${(props) => props.inverted ? '#5A41F3' : '#fff'};
    color: ${(props) => props.inverted ? '#fff' : '#5A41F3'};;
  }
`;