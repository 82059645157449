import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

import cardYellow from '../../images/how-card-yellow.svg';
import cardOrange from '../../images/how-card-orange.svg';
import cardGreen from '../../images/how-card-green.svg';
import cardRed from '../../images/how-card-red.svg';

export const Container = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 1220px;
  padding: 70px 20px 0;

  ${mq('m')} {
    padding-top: 30px;
  }

  ${mq('s')} {

  }
`;

export const Content = styled.div`
  padding: 0 0 80px;

  ${mq('s')} {
    padding-bottom: 30px;
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    color: #1E1433;
    max-width: 720px;
    margin: 0 auto;
    text-align: center;

    ${mq('s')} {
      text-align: left;
      font-size: 28px;
    }

    small {
      display: block;
      font-size: 12px;
      font-weight: 600;
      color: #ACA0F9;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      margin-bottom: 10px;
    }

    strong {
      font-weight: 600;
      color: #5A41F3;
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  position: relative;
  z-index: 3;
  margin: 0 0 -60px;

  ${mq('s')} {
    flex-direction: column;
    margin-bottom: -100px;
  }
`;

export const Card = styled.div`
  position: relative;
  z-index: 2;
  width: calc(100% / 3);
  margin: 0;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 6px 16px rgba(30, 20, 51, 0.1);

  ${mq('s')} {
    width: 100%;
    margin: 0 0 20px;
  }

  &:first-child {
    margin-left: 10px;

    ${mq('s')} {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: 10px;

    ${mq('s')} {
      margin-right: 0;
    }
  }

  &.highlight {
    margin-top: -44px;
    margin-left: -10px;
    margin-right: -10px;
    padding-bottom: 44px;
    z-index: 3;

    ${mq('s')} {
      margin: 0 0 20px;
      padding-bottom: 0;
    }
  }

  

  .inner {
    text-align: center;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  .header {
    padding: 60px 25px 30px;

    h3 {
      font-weight: 600;
      font-size: 36px;
      line-height: 36px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #5A41F3;
    }

    ul li {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #4B435C;
      margin: 0;
    }
  }

  .price {
    padding: 20px 25px calc(20px + 1em);
    background: #EFECFE;

    strong {
      font-weight: 600;
      font-size: 40px;
      line-height: 50px;
      letter-spacing: -0.01em;
      color: #4B435C;
    }

    em {
      display: inline-block;
      transform: translate(0,1em);
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      text-align: center;

      color: #4B435C;
    }
  }

  .features {
    padding: 40px 25px 20px;

    .show-detail {
      display: none;

      ${mq('s')} {
        display: block;
      }

      button {
        background: transparent;
        border: 0;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        color: #5A41F3;
      }
    }

    ul {
      max-width: 212px;
      margin: 0 auto;

      ${mq('s')} {
        overflow: hidden;
        transition: max-height .6s ease-in-out;
      }

      &.opened {
        ${mq('s')} {
          max-height: 250px;
        }
      }

      &.closed {
        ${mq('s')} {
          max-height: 0;
        }
      }

      li {
        position: relative;
        text-align: left;
        padding-left: 27px;

        &::before {
          content: '';
          display: flex;
          position: absolute;
          top: 5px;
          left: 0;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Ctitle%3ECheckmark%3C/title%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M416 128L192 384l-96-96'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 10px 10px;;
        }
        &.enabled {
          color: #1E1433;
          &::before {
            background-color: #83C2BE;
          }
        }

        &.disabled {
          color: #E8E7EB;
          &::before {
            background-color: #E8E7EB;
          }
        }

        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
  }

  

`;
