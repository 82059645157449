import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

import cardYellow from '../../images/how-card-yellow.svg';
import cardOrange from '../../images/how-card-orange.svg';
import cardGreen from '../../images/how-card-green.svg';
import cardRed from '../../images/how-card-red.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1220px;
  padding: 170px 20px 0;

  ${mq('m')} {
    flex-direction: column;
    margin-bottom: 60px;
    padding-top: 70px;
  }

  ${mq('s')} {
    padding-top: 60px;
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  width: 40%;
  padding: 40px 0 0;

  ${mq('m')} {
    width: 100%;
  }

  ${mq('s')} {
    padding-top: 0;
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    color: #1E1433;
    max-width: 463px;
    margin-bottom: 30px;

    ${mq('m')} {
      max-width: none;
      margin-bottom: 50px;
    }

    ${mq('s')} {
      font-size: 28px;
    }

    small {
      display: block;
      font-size: 12px;
      font-weight: 600;
      color: #ACA0F9;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      margin-bottom: 10px;
    }

    strong {
      font-weight: 600;
      color: #5A41F3;
    }
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(49% + 20px);
  margin: 0;
  padding-bottom: calc(393px * 0.20);

  ${mq('m')} {
    width: 100%;
    margin-left: -10px;
    margin-right: -10px;
  }

  ${mq('s')} {
    padding-bottom: 0;
    margin-left: -20px;
    margin-right: -20px;
    display: block;
    width: calc(100% - 30px);
    
  }

  .slick-slider {
    ${mq('s', true)} {
      width: 100%;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-list {
    ${mq('s', true)} {
      width: 100%;
      overflow: visible !important;
    }

    ${mq('s')} {
      overflow: visible;
      margin-left: 10px;
    }
  }

  .slick-track {
    ${mq('s', true)} {
      display: flex !important;
      width: 100% !important;
      transform: none !important;
      opacity: 1 !important;
      flex-wrap: wrap;
      overflow: visible !important;
    }

    ${mq('s')} {
      display: flex;
    }
  }

  .slick-slide {
    ${mq('s', true)} {
      width: 50% !important;
      height: auto;
      float: none;
    }

    ${mq('s')} {
      display: flex;
      padding: 10px;
      height: auto;
    }

    > div {
      ${mq('s')} {
        height: 100%;
      }
    }

    .card-wrapper {
      ${mq('s')} {
        height: 100%;
      }
    }


    &:not(.slick-cloned):nth-child(odd) {
      transform: translate(0, 40%);

      ${mq('s')} {
        transform: translate(0, 0);
      }
    }
  }

  .slick-cloned {
    ${mq('s', true)} {
      display: none !important;
    }
  }
`;

export const Card = styled.div`
  position: relative;
  width: calc(100% - 20px);
  margin: 0 10px 20px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 6px 16px rgba(30, 20, 51, 0.1);

  ${mq('s')} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    height: 100%;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 140%;

    ${mq('m')} {
      padding-bottom: 100%;
    }

    ${mq('s')} {
      padding-bottom: 80%;
      display: none;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 74%;
    padding-bottom: 76%;
    background: transparent none no-repeat 100% 0;
    background-size: contain;
    border-radius: 0 8px 0 0;
    overflow: hidden;
    pointer-events: none;
  }

  /* &:nth-child(even) {
    transform: translate(0, 40%);

    ${mq('s')} {
      transform: translate(0, 0);
    }
  } */

  &:nth-child(odd) {
    
  }

  .inner {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 30px;
    z-index: 5;

    ${mq('s')} {
      top: 0;
      width: 75%;
      display: flex;
      align-items: center;
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    color: #1E1433;

    ${mq('s')} {
      margin-bottom: 0;
    }
  }

  &.yellow::after {
    background-image: url(${cardYellow});
  }

  &.orange::after {
    background-image: url(${cardOrange});
  }

  &.green::after {
    background-image: url(${cardGreen});
  }

  &.red::after {
    background-image: url(${cardRed});
  }
`;

export const Cols = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  .title,
  .highlight {
    font-size: 40px;
    font-weight: 600;
    color: #1E1433;
    max-width: 463px;

    small {
      display: block;
      font-size: 12px;
      font-weight: 600;
      color: #ACA0F9;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      margin-bottom: 10px;
    }

    strong {
      font-weight: 600;
      color: #5A41F3;
    }
  }

  .highlight {
    margin: 0 auto;
  }

  .intro {
    column-count: 2;
    column-gap: 10px;

    p {
      font-size: 18px;
      line-height: 28px;
    }
  }
`;
