import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '../common/button';

import { Container, Content, Cards, Card } from './styled.js';

const Pricing = (props) => {

  const [showDetailFree, setShowDetailFree] = useState(false);
  const [showDetailStandard, setShowDetailStandard] = useState(false);
  const [showDetailCustom, setShowDetailCustom] = useState(false);

  return (
    <Container>

      <Content id={(props.id) ? props.id : ''}>
        <h2 className="title">
          <small>pricing</small>
          Manage, ça coûte combien ?
        </h2>
      </Content>

      <Cards>
        <Card>
          <div className="inner">
            <div class="header">
              <h3>Gratuit</h3>
              <ul>
                <li>Jusqu'à 9 employés</li>
                <li>5 Go d'espace disque</li>
              </ul>
            </div>

            <div className="price">
              <strong>0€</strong>
              <em>/ Emp./Mois</em>
            </div>
            <div className="features">
              <ul className={(showDetailFree) ? 'opened' : 'closed'}>
                <li className="enabled">Fiche de paie</li>
                <li className="enabled">Gestion</li>
                <li className="enabled">Rapports</li>
                <li className="disabled">Lien avec les sécurités sociales</li>
                <li className="disabled">Stratégie</li>
                <li className="disabled">Possibilité de module personnalisable sur devis</li>
              </ul>
              <div className="show-detail">
                <button
                  onClick={
                    () => {
                      setShowDetailFree(!showDetailFree);
                    }
                  }
                >
                  {(showDetailFree) ? (<span>Cacher les détails</span>) : (<span>Voir les détails</span>)}
                </button>
              </div>
            </div>
          </div>
        </Card>

        <Card className="highlight">
        <div className="inner">
            <div class="header">
              <h3>Standard</h3>
              <ul>
                <li>Jusqu'à 99 employés</li>
                <li>Espace disque illimité</li>
              </ul>
            </div>

            <div className="price">
              <strong>10€</strong>
              <em>/ Emp./Mois</em>
            </div>
            <div className="features">
              <ul className={(showDetailStandard) ? 'opened' : 'closed'}>
                <li className="enabled">Fiche de paie</li>
                <li className="enabled">Gestion</li>
                <li className="enabled">Rapports</li>
                <li className="enabled">Lien avec les sécurités sociales</li>
                <li className="enabled">Stratégie</li>
                <li className="disabled">Possibilité de module personnalisable sur devis</li>
              </ul>
              <div className="show-detail">
                <button
                  onClick={
                    () => {
                      setShowDetailStandard(!showDetailStandard);
                    }
                  }
                >
                  {(showDetailStandard) ? (<span>Cacher les détails</span>) : (<span>Voir les détails</span>)}
                </button>
              </div>
            </div>
          </div>
        </Card>

        <Card>
        <div className="inner">
            <div class="header">
              <h3>Premium</h3>
              <ul>
                <li>Employés illimité</li>
                <li>Espace disque illimité</li>
              </ul>
            </div>

            <div className="price price-custom"> 
              <strong>Devis</strong>
            </div>
            <div className="features">
              <ul className={(showDetailCustom) ? 'opened' : 'closed'}>
                <li className="enabled">Fiche de paie</li>
                <li className="enabled">Gestion</li>
                <li className="enabled">Rapports</li>
                <li className="enabled">Lien avec les sécurités sociales</li>
                <li className="enabled">Stratégie</li>
                <li className="enabled">Possibilité de module personnalisable sur devis</li>
              </ul>
              <div className="show-detail">
                <button
                  onClick={
                    () => {
                      setShowDetailCustom(!showDetailCustom);
                    }
                  }
                >
                  {(showDetailCustom) ? (<span>Cacher les détails</span>) : (<span>Voir les détails</span>)}
                </button>
              </div>
            </div>
          </div>
        </Card>
      </Cards>
    </Container>
  );
};

Pricing.propTypes = {
}

Pricing.defaultProps = {
}

export default Pricing
