import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

export const Container = styled.div`
  position: relative;
  display: flex;
  margin: 0 auto;
  max-width: 1220px;
  padding: 80px 20px 0;

  ${mq('m')} {
    margin-bottom: 30px;
  }

  ${mq('s')} {
    padding-top: 0;
  }
`;

export const Cols = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px;

  ${mq('m')} {
    flex-direction: column;
  }

  .title,
  .highlight {
    font-size: 40px;
    font-weight: 600;
    color: #1E1433;
    max-width: 463px;

    ${mq('m')} {
      max-width: none;
    }

    ${mq('s')} {
      font-size: 28px;
    }

    small {
      display: block;
      font-size: 12px;
      font-weight: 600;
      color: #ACA0F9;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      margin-bottom: 10px;
    }

    strong {
      font-weight: 600;
      color: #5A41F3;
    }
  }

  .highlight {
    margin: 0 auto;

    ${mq('m')} {
      margin-top: 50px;
      margin-bottom: 90px;
    }

    ${mq('s')} {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  .intro {
    column-count: 2;
    column-gap: 10px;

    ${mq('m')} {
      margin-bottom: 40px;
    }

    ${mq('s')} {
      column-count: 1;
    }

    p {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${mq('s')} {
      flex-direction: column-reverse;
    }

    .card {
      ${mq('s')} {
        margin-bottom: -100px;
        position: relative;
        z-index: 4;
      }
    }
  }
`;

export const Col = styled.div`
  width: calc(50% - 20px);
  margin: 0 10px 70px;

  ${mq('m')} {
    width: calc(100% - 20px);
    margin-bottom: 0;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  padding: 27px 30px;
  margin: 0 0 18px;

  background: #FFFFFF;
  box-shadow: 0px 6px 16px rgba(30, 20, 51, 0.1);
  border-radius: 8px;

  ${mq('s')} {
    position: relative;
    padding: 27px 10px;
  }

  .visual {
    margin-left: 20px;

    ${mq('s')} {
      position: absolute;
      top: 15px;
      right: 10px;
      display: block;
      width: 70px;
      height: 70px;
    }

    img {
      margin-bottom: 0;
    }
  }

  .content {
    flex: 1;

    h3 {
      font-size: 20px;
      color: #1E1433;
      ${mq('s')} {
        padding-right: 100px;
        min-height: 44px;
        display: flex;
        align-items: center;
      }
    }

    p {
      margin-bottom: 0;
      color: #787285;
      font-size: 16px;
    }
  }

`;