import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";

import logo from '../../images/logo.svg';

import { HeaderWrapper, Container, HeaderInner, BurgerMenu, BurgerMenuInner, BurgerMenuTrigger, LogoWrapper, Menu } from './styled.js';
import { Button } from '../common/button';

const Header = ({ dark, siteTitle, currentPage, toggleMenu, currentBreakPoint, openedMenu }) => {

  // useEffect(() => {
  //   document.addEventListener('scroll', () => {
  //     console.log('scroll', window.scrollY);
  //   }, []);
  // });

  const headerWrapperRef = useRef(null);
  let headerShowed = true;
  let previousScrollY = 0;

  const debounce = (func, wait, immediate) => {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  useLayoutEffect(() => {
    document.addEventListener('scroll', debounce(() => {
      // console.log('scroll (useLayoutEffect)', window.scrollY, headerWrapperRef.current);
      if(headerShowed) {
        if(window.scrollY > 80) {
          if(headerWrapperRef && headerWrapperRef.current){
            headerWrapperRef.current.classList.add('show');
            headerShowed = false;
          }
        }
      } else {
        if(window.scrollY < 80) {
          if(headerWrapperRef && headerWrapperRef.current){
            headerWrapperRef.current.classList.remove('show');
            headerShowed = true;
          }
        }
      }

      previousScrollY = window.scrollY;
    }, 10));
  }, []);

  return (
    <HeaderWrapper ref={headerWrapperRef}>
      <Container>
      <HeaderInner>
        <LogoWrapper>
            <AnchorLink to="/#top" title="Manage" className="logo">
              <img src={logo} alt="Manage" />
            </AnchorLink>
        </LogoWrapper>
          <Menu>
            <ul>
              <li>
                <AnchorLink to="/#who" title="Qui utilise Manage ?">
                  <span>Qui utilise Manage ?</span>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/#how" title="Qui utilise Manage ?">
                  <span>Comment fonctionne Manage ?</span>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/#where" title="Dans quels pays ?">
                  <span>Dans quels pays ?</span>
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/#pricing" title="Pricing">
                  <span>Pricing</span>
                </AnchorLink>
              </li>
              <li className="btn-wrapper">
                <Button href="mailto: juliette@hellomanage.app" target="_blank">
                  <span>Contactez-nous</span>
                  <span className="mobile-only">Contact</span>
                </Button>
              </li>
            </ul>
          </Menu>
        </HeaderInner>
      </Container>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
