import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from "../components/common/section";
import { Image } from "../components/image";

import Hero from '../components/hero';
import Who from '../components/who';
import Demo from '../components/demo';
import How from '../components/how';
import Features from '../components/features';
import Where from '../components/where';
import Pricing from '../components/pricing';
import FooterBanner from '../components/footer-banner';

import screenshot from '../images/screenshot.png';



const IndexPage = () => {
  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  const updateBreakpoint = currentBreakPoint => {
    setCurrentBreakpointChildren(currentBreakPoint)
  }

  useEffect(() => {
    
  }, [])

  return (
    <Layout
      currentPage="homepage"
      headerDark={true}
      updateBreakpoint={updateBreakpoint}
    >
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />


      <Hero />

      <Section className="deco-1">
        <div className="visual-screenshot-wrapper">
          <div className="visual-screenshot">
            <img src={screenshot} alt='' />
          </div>
        </div>

        <Who id="who" />

        <Demo />
      </Section>

      <Section className="deco-2">
        <How id="how" />
      </Section>

      <Section className="white">
        <Features />
      </Section>

      <Section className="cutted white">
        <Where id="where" />
      </Section>

      <Section className="deco-3 white">
        <Pricing id="pricing" />
      </Section>

      <Section>
        <FooterBanner />
      </Section>
      
    </Layout>
  )
}

export default IndexPage
