import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from '../common/button';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Container, Content, Cards, Card } from './styled.js';

const How = (props) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 691,
        settings: { unslick: true }
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };


  return (
    <Container>

      <Content id={(props.id) ? props.id : ''}>
        <h2 className="title">
          <small>comment fonctionne manage ?</small>
          Une plateforme online sécurisée qui offre aux entreprises une <strong>gestion globale et optimale des ressources humaines.</strong>
        </h2>
      </Content>

      <Cards>
        <Slider {...settings}>
          <div className="card-wrapper">
          <Card className="yellow">
            <div className="inner">
              <h2>Est un pur “player” en respect avec les normes sociales et sécurité</h2>
            </div>
          </Card>
          </div>
          <div className="card-wrapper">
          <Card className="orange">
            <div className="inner">
              <h2>Aide à améliorer la gestion via des outils Soft RH et Hard RH complets mais accessibles</h2>
            </div>
          </Card>
          </div>
          <div className="card-wrapper">
          <Card className="green">
            <div className="inner">
              <h2>Permet de se baser sur son équipe pour améliorer le management de son entreprise</h2>
            </div>
          </Card>
          </div>
          <div className="card-wrapper">
          <Card className="red">
            <div className="inner">
              <h2>Devient votre point central de communication et de suivi entre vos différents profils au sein de votre entreprise</h2>
            </div>
          </Card>
          </div>
        </Slider>
      </Cards>
    </Container>
  );
};

How.propTypes = {
}

How.defaultProps = {
}

export default How
