import styled from 'styled-components';
import variables, { mq, isTouchMedia } from '../../utils/variables';

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0px 6px 9px rgba(6, 10, 74, 0.05);
  padding: 0 40px;
  transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out;

  ${mq('s')} {
    padding: 0 20px;
    height: 70px;
    background: transparent;
    box-shadow: 0px 6px 9px rgba(6, 10, 74, 0.0001);

    .btn-wrapper {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.show {
    background: #fff;
    box-shadow: 0px 6px 9px rgba(6, 10, 74, 0.05);

    .btn-wrapper {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1340px;
`;

export const LogoWrapper = styled.div`

  ${mq('s')} {
    margin-left: -15px;
  }

  img {
    display: block;
    margin: 5px 0;
  }
`;

export const HeaderInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1010;
`;

export const Menu = styled.div`
  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    align-items: center;

    li {
      margin: 0;

      ${mq('xl')} {
        display: none;
      }

      + li {
        margin-left: 25px;

        &.btn-wrapper {
          margin-left: 50px;

          ${mq('xl')} {
            display: list-item;
          }

          span {
            ${mq('s')} {
              display: none;
            }
          }

          .mobile-only {
            display: none;

            ${mq('s')} {
              display: inline;
            }
          }
        }
      }

      &:not(.btn-wrapper) a,
      &:not(.btn-wrapper) button {
        font-family: 'Sofia Pro', sans-serif;
        font-weight: 500;
        text-decoration: none;
        color: #1E1433;
        font-size: 16px;
        border: 0;
        background: transparent none;
        cursor: pointer;

        &:hover {
          color: #5A41F3;
        }
      }
    }
  }
`;